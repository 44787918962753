import { ServiceFetch } from '../service-fetch'

export default class SchedulePlanModel extends ServiceFetch {
    generateSchedulePlanLastCode = (data) => this.authFetch({
        url: "schedule-plan/generateSchedulePlanLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSchedulePlanBy = (data) => this.authFetch({
        url: "schedule-plan/getschedulePlanBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSchedulePlanByCode = (data) => this.authFetch({
        url: "schedule-plan/getschedulePlanByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSchedulePlanBy = (data) => this.authFetch({
        url: "schedule-plan/updateschedulePlanBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertSchedulePlan = (data) => this.authFetch({
        url: "schedule-plan/insertschedulePlan",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSchedulePlanByCode = (data) => this.authFetch({
        url: "schedule-plan/deleteschedulePlanByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSchedulePlanByDate = (data) => this.authFetch({
        url: "schedule-plan/deleteSchedulePlanByDate",
        method: "POST",
        body: JSON.stringify(data),
    })

}