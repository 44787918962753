import { ServiceFetch } from '../service-fetch'

export default class StockUnpackListModel extends ServiceFetch {
    getStockUnpackListBy = (data) => this.authFetch({
        url: 'stock-unpack-list/getStockUnpackListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockUnpackListBy = (data) => this.authFetch({
        url: 'stock-unpack-list/generateStockUnpackListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}