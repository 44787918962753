import { ServiceFetch } from '../service-fetch'

export default class StockMoveModel extends ServiceFetch {
    generateStockMoveLastCode = (data) => this.authFetch({
        url: 'stock-move/generateStockMoveLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/getStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockMoveByCode = (data) => this.authFetch({
        url: 'stock-move/getStockMoveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/updateStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockReceiveMoveBy = (data) => this.authFetch({
        url: 'stock-move/updateStockReceiveMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    receiveStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/receiveStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveStockMoveBy = (data) => this.authFetch({
        url: 'stock-move/approveStockMoveBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockMove = (data) => this.authFetch({
        url: 'stock-move/insertStockMove',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockMoveByCode = (data) => this.authFetch({
        url: 'stock-move/deleteStockMoveByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}