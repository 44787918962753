import { ServiceFetch } from '../service-fetch'

export default class SaleDepositListModel extends ServiceFetch {
    getSaleDepositListBy = (data) => this.authFetch({
        url: 'sale-deposit-list/getSaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateSaleDepositListBy = (data) => this.authFetch({
        url: 'sale-deposit-list/generateSaleDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}