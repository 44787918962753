import { ServiceFetch } from '../service-fetch'

export default class StockPoorModel extends ServiceFetch {
    getStockPoorBy = (data) => this.authFetch({
        url: 'stock-poor/getStockPoorBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockPoorByCode = (data) => this.authFetch({
        url: 'stock-poor/getStockPoorByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockPoorBy = (data) => this.authFetch({
        url: 'stock-poor/updateStockPoorBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockPoorClaimBy = (data) => this.authFetch({
        url: 'stock-poor/updateStockPoorClaimBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertStockPoor = (data) => this.authFetch({
        url: 'stock-poor/insertStockPoor',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockPoorByCode = (data) => this.authFetch({
        url: 'stock-poor/deleteStockPoorByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}