import { ServiceFetch } from '../service-fetch'

export default class FinanceDebitListModel extends ServiceFetch {
    getFinanceDebitListBy = (data) => this.authFetch({
        url: 'finance-debit-list/getFinanceDebitListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateFinanceDebitListBy = (data) => this.authFetch({
        url: 'finance-debit-list/generateFinanceDebitListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}