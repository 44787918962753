import { ServiceFetch } from '../service-fetch'

export default class DebitNoteSupplierOtherListModel extends ServiceFetch {
    getDebitNoteSupplierOtherListBy = (data) => this.authFetch({
        url: 'debit-note-supplier-other-list/getDebitNoteSupplierOtherListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDebitNoteSupplierOtherListBy = (data) => this.authFetch({
        url: 'debit-note-supplier-other-list/generateDebitNoteSupplierOtherListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}