import { ServiceFetch } from '../service-fetch'

export default class RedeemGiftModel extends ServiceFetch {
    generateRedeemGiftLastCode = (data) => this.authFetch({
        url: 'redeem-gift/generateRedeemGiftLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRedeemGiftBy = (data) => this.authFetch({
        url: 'redeem-gift/getRedeemGiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRedeemGiftByCode = (data) => this.authFetch({
        url: 'redeem-gift/getRedeemGiftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveRedeemGiftBy = (data) => this.authFetch({
        url: 'redeem-gift/approveRedeemGiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateRedeemGiftBy = (data) => this.authFetch({
        url: 'redeem-gift/updateRedeemGiftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertRedeemGift = (data) => this.authFetch({
        url: 'redeem-gift/insertRedeemGift',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteRedeemGiftByCode = (data) => this.authFetch({
        url: 'redeem-gift/deleteRedeemGiftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}