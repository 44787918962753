import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierListDraftModel extends ServiceFetch {
    getInvoiceSupplierListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-draft/getInvoiceSupplierListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-draft/getLastInvoiceSupplierListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierListDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-list-draft/generateInvoiceSupplierListDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}