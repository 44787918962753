import { ServiceFetch } from '../service-fetch'

export default class StockBalanceModel extends ServiceFetch {
    getStockBalanceBy = (data) => this.authFetch({
        url: 'stock-balance/getStockBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getStockBalanceByCode = (data) => this.authFetch({
        url: 'stock-balance/getStockBalanceByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductStockBalanceBy = (data) => this.authFetch({
        url: 'stock-balance/getProductStockBalanceBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}