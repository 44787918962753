import { ServiceFetch } from '../service-fetch'

export default class ExpensesNoteModel extends ServiceFetch {
    generateExpensesNoteLastCode = (data) => this.authFetch({
        url: 'expenses-note/generateExpensesNoteLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getExpensesNoteBy = (data) => this.authFetch({
        url: 'expenses-note/getExpensesNoteBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getExpensesNoteByCode = (data) => this.authFetch({
        url: 'expenses-note/getExpensesNoteByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseExpensesNote = (data) => this.authFetch({
        url: 'expenses-note/getAdviseExpensesNote',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateExpensesNoteBy = (data) => this.authFetch({
        url: 'expenses-note/updateExpensesNoteBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertExpensesNote = (data) => this.authFetch({
        url: 'expenses-note/insertExpensesNote',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteExpensesNoteByCode = (data) => this.authFetch({
        url: 'expenses-note/deleteExpensesNoteByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}