import { ServiceFetch } from '../service-fetch'

export default class SupplierModel extends ServiceFetch {
    generateSupplierLastCode = (data) => this.authFetch({
        url: "supplier/generateSupplierLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSupplierBy = (data) => this.authFetch({
        url: "supplier/getSupplierBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSupplierByCode = (data) => this.authFetch({
        url: "supplier/getSupplierByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSupplierBy = (data) => this.authFetch({
        url: "supplier/updateSupplierBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertSupplier = (data) => this.authFetch({
        url: "supplier/insertSupplier",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSupplierByCode = (data) => this.authFetch({
        url: "supplier/deleteSupplierByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}