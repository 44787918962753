import { ServiceFetch } from '../service-fetch'

export default class BillingNoteCustomerListModel extends ServiceFetch {
    getBillingNoteCustomerListBy = (data) => this.authFetch({
        url: 'billing-note-customer-list/getBillingNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateBillingNoteCustomerListBy = (data) => this.authFetch({
        url: 'billing-note-customer-list/generateBillingNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}