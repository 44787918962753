import { ServiceFetch } from '../service-fetch'

export default class PettyCashWithdrawalModel extends ServiceFetch {
    generatePettyCashWithdrawalLastCode = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/generatePettyCashWithdrawalLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPettyCashWithdrawalBy = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/getPettyCashWithdrawalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPettyCashWithdrawalByCode = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/getPettyCashWithdrawalByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdvisePettyCashWithdrawal = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/getAdvisePettyCashWithdrawal',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approvePettyCashWithdrawalBy = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/approvePettyCashWithdrawalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePettyCashWithdrawalBy = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/updatePettyCashWithdrawalBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPettyCashWithdrawal = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/insertPettyCashWithdrawal',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePettyCashWithdrawalByCode = (data) => this.authFetch({
        url: 'petty-cash-withdrawal/deletePettyCashWithdrawalByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}