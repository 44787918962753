import { ServiceFetch } from '../service-fetch'

export default class PurchaseDepositInvoiceModel extends ServiceFetch {
  getPurchaseDepositInvoiceByCode = (data) => this.authFetch({
    url: 'purchase-deposit-invoice/getPurchaseDepositInvoiceByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  generatePurchaseDepositInvoiceBy = (data) => this.authFetch({
    url: 'purchase-deposit-invoice/generatePurchaseDepositInvoiceBy',
    method: 'POST',
    body: JSON.stringify(data),
  })
}