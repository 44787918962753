import { ServiceFetch } from '../service-fetch'

export default class ProductSacleModel extends ServiceFetch {
    getProductScaleBy = (data) => this.authFetch({
        url: 'product-scale/getProductScaleBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getProductScaleAllByProductCode = (data) => this.authFetch({
        url: 'product-scale/getProductScaleAllByProductCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getProductScaleByProductCode = (data) => this.authFetch({
        url: 'product-scale/getProductScaleByProductCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}