import { ServiceFetch } from '../service-fetch'

export default class DebitNoteCustomerListModel extends ServiceFetch {
    getDebitNoteCustomerListBy = (data) => this.authFetch({
        url: 'debit-note-customer-list/getDebitNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateDebitNoteCustomerListBy = (data) => this.authFetch({
        url: 'debit-note-customer-list/generateDebitNoteCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}