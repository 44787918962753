import { ServiceFetch } from '../service-fetch'

export default class PointsSettingModel extends ServiceFetch {
    getPointsSetting = (data) => this.authFetch({
        url: 'points-setting/getPointsSetting',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePointsSettingBy = (data) => this.authFetch({
        url: 'points-setting/updatePointsSettingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}