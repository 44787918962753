import { ServiceFetch } from '../service-fetch'

export default class StockIssueTypeTypeModel extends ServiceFetch {
    getStockIssueTypeBy = (data) => this.authFetch({
        url: 'stock-issue-type/getStockIssueTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getStockIssueTypeByCode = (data) => this.authFetch({
        url: 'stock-issue-type/getStockIssueTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateStockIssueTypeBy = (data) => this.authFetch({
        url: 'stock-issue-type/updateStockIssueTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteStockIssueTypeByCode = (data) => this.authFetch({
        url: 'stock-issue-type/deleteStockIssueTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}