import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierListModel extends ServiceFetch {
    getInvoiceSupplierListBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/getInvoiceSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierListBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/getLastInvoiceSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierListBy = (data) => this.authFetch({
        url: 'invoice-supplier-list/generateInvoiceSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}