import { ServiceFetch } from '../service-fetch'

export default class SalaryPaySlipModel extends ServiceFetch {
    generateSalaryPaySlipLastCode = (data) => this.authFetch({
        url: 'salary-pay-slip/generateSalaryPaySlipLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalaryPaySlipBy = (data) => this.authFetch({
        url: 'salary-pay-slip/getSalaryPaySlipBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalaryPaySlipByCode = (data) => this.authFetch({
        url: 'salary-pay-slip/getSalaryPaySlipByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSalaryPaySlipBy = (data) => this.authFetch({
        url: 'salary-pay-slip/updateSalaryPaySlipBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSalaryPaySlip = (data) => this.authFetch({
        url: 'salary-pay-slip/insertSalaryPaySlip',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSalaryPaySlipByCode = (data) => this.authFetch({
        url: 'salary-pay-slip/deleteSalaryPaySlipByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveSalaryPaySlipBy = (data) => this.authFetch({
        url: 'salary-pay-slip/approveSalaryPaySlipBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}