function roundNumber(num, scale) {
  var num_buf = num.toFixed(scale + 2);
  num = parseFloat(num_buf);
  if (!("" + num).includes("e")) {
    return +(Math.round(num + "e+" + scale) + "e-" + scale);
  } else {
    var arr = ("" + num).split("e");
    var sig = ""
    if (+arr[1] + scale > 0) {
      sig = "+";
    }
    return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}
const numberFunc = {
  toInt: (number) => number ? ~~+number.toString().replace(new RegExp(',', 'g'), '') : 0,
  toFloat: (number) => number ? +number.toString().replace(new RegExp(',', 'g'), '') : 0,
  decimalFix: (number, decimal = 2, nzero = true) => {
    number = numberFunc.toFloat(number)
    number = roundNumber(number, decimal)

    return !nzero && number === 0 ? '' : number.toFixed(decimal).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
  }
}

export default numberFunc