import { ServiceFetch } from '../service-fetch'

export default class ReceiptModel extends ServiceFetch {
    generateReceiptLastCode = (data) => this.authFetch({
        url: 'receipt/generateReceiptLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getReceiptBySaleCloseShift = (data) => this.authFetch({
        url: 'receipt/getReceiptBySaleCloseShift',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getReceiptBy = (data) => this.authFetch({
        url: 'receipt/getReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getReceiptReportBy = (data) => this.authFetch({
        url: 'receipt/getReceiptReportBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptReportByUser = (data) => this.authFetch({
        url: 'receipt/getReceiptReportByUser',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptReportDayBy = (data) => this.authFetch({
        url: 'receipt/getReceiptReportDayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptReportMonthBy = (data) => this.authFetch({
        url: 'receipt/getReceiptReportMonthBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptReportYearBy = (data) => this.authFetch({
        url: 'receipt/getReceiptReportYearBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptReportProductCategoryBy = (data) => this.authFetch({
        url: 'receipt/getReceiptReportProductCategoryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
     
    getReceiptCopyByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptCopyByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptCopyOldByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptCopyOldByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getReceiptByCode = (data) => this.authFetch({
        url: 'receipt/getReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRequestCancelReceipt = (data) => this.authFetch({
        url: 'receipt/getRequestCancelReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSignatureReceiptByCode = (data) => this.authFetch({
        url: 'receipt/updateSignatureReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    approveCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/approveCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    notCancelReceiptByCode = (data) => this.authFetch({
        url: 'receipt/notCancelReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertReceipt = (data) => this.authFetch({
        url: 'receipt/insertReceipt',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteReceiptByCode = (data) => this.authFetch({
        url: 'receipt/deleteReceiptByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getRequestReceiptReturnBy = (data) => this.authFetch({
        url: 'receipt/getRequestReceiptReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    

    getRequestReceiptReturnByCode = (data) => this.authFetch({
        url: 'receipt/getRequestReceiptReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getAdviseCheckRequestReturn = (data) => this.authFetch({
        url: 'receipt/getAdviseCheckRequestReturn',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    getRequestReceiptReturnListBy = (data) => this.authFetch({
        url: 'receipt/getRequestReceiptReturnListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    verifyRequestReturnByCode = (data) => this.authFetch({
        url: 'receipt/verifyRequestReturnByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    
}