import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierMaterialModel extends ServiceFetch {
    generateInvoiceSupplierMaterialLastCode = (data) => this.authFetch({
        url: 'invoice-supplier-material/generateInvoiceSupplierMaterialLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierMaterialBy = (data) => this.authFetch({
        url: 'invoice-supplier-material/getInvoiceSupplierMaterialBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierMaterialByCode = (data) => this.authFetch({
        url: 'invoice-supplier-material/getInvoiceSupplierMaterialByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierMaterialBy = (data) => this.authFetch({
        url: 'invoice-supplier-material/updateInvoiceSupplierMaterialBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceSupplierMaterial = (data) => this.authFetch({
        url: 'invoice-supplier-material/insertInvoiceSupplierMaterial',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceSupplierMaterialByCode = (data) => this.authFetch({
        url: 'invoice-supplier-material/deleteInvoiceSupplierMaterialByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkInvoiceSupplierMaterialPaperCodeBy = (data) => this.authFetch({
        url: 'invoice-supplier-material/checkInvoiceSupplierMaterialPaperCodeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}