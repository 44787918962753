import { ServiceFetch } from '../service-fetch'

export default class PayrollDefaultModel extends ServiceFetch {
    generatePayrollDefaultLastCode = (data) => this.authFetch({
        url: 'payroll-default/generatePayrollDefaultLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPayrollDefaultBy = (data) => this.authFetch({
        url: 'payroll-default/getPayrollDefaultBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPayrollDefaultByCode = (data) => this.authFetch({
        url: 'payroll-default/getPayrollDefaultByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePayrollDefaultBy = (data) => this.authFetch({
        url: 'payroll-default/updatePayrollDefaultBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPayrollDefault = (data) => this.authFetch({
        url: 'payroll-default/insertPayrollDefault',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePayrollDefaultByCode = (data) => this.authFetch({
        url: 'payroll-default/deletePayrollDefaultByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}