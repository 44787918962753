import { ServiceFetch } from '../service-fetch'

export default class LicenseModel extends ServiceFetch {
    generateLicenseLastCode = (data) => this.authFetch({
        url: 'license/generateLicenseLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLicenseBy = (data) => this.authFetch({
        url: 'license/getLicenseBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLicenseByCode = (data) => this.authFetch({
        url: 'license/getLicenseByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateLicenseBy = (data) => this.authFetch({
        url: 'license/updateLicenseBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertLicense = (data) => this.authFetch({
        url: 'license/insertLicense',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteLicenseByCode = (data) => this.authFetch({
        url: 'license/deleteLicenseByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}