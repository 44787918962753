import { ServiceFetch } from '../service-fetch'

export default class InvoiceCustomerListModel extends ServiceFetch {
    getInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/generateInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getLastInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAverageInvoiceCustomerListBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getAverageInvoiceCustomerListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeSaleBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getIncomeSaleBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeCustomerBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getIncomeCustomerBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getIncomeProductBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getIncomeProductBy',
        method: 'POST',
        body: JSON.stringify(data),
    })


    getIncomeReceiptBy = (data) => this.authFetch({
        url: 'invoice-customer-list/getIncomeReceiptBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}