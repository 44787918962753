import { ServiceFetch } from '../service-fetch'

export default class StockVerifyCountListModel extends ServiceFetch {
    getStockVerifyCountListBy = (data) => this.authFetch({
        url: 'stock-verify-count-list/getStockVerifyCountListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getStockVerifyCountListByCode = (data) => this.authFetch({
        url: 'stock-verify-count-list/getStockVerifyCountListByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}