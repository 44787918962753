import { ServiceFetch } from '../service-fetch'

export default class DayOffUserModel extends ServiceFetch {
    generateDayOffUserLastCode = (data) => this.authFetch({
        url: 'day-off-user/generateDayOffUserLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDayOffUserBy = (data) => this.authFetch({
        url: 'day-off-user/getDayOffUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDayOffUserByCode = (data) => this.authFetch({
        url: 'day-off-user/getDayOffUserByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDayOffUserBy = (data) => this.authFetch({
        url: 'day-off-user/updateDayOffUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDayOffUser = (data) => this.authFetch({
        url: 'day-off-user/insertDayOffUser',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDayOffUserByCode = (data) => this.authFetch({
        url: 'day-off-user/deleteDayOffUserByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDayOffUserAllByCode = (data) => this.authFetch({
        url: 'day-off-user/deleteDayOffUserAllByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
}