import { ServiceFetch } from '../service-fetch'

export default class LeaveTypeModel extends ServiceFetch {
    generateLeaveTypeLastCode = (data) => this.authFetch({
        url: 'leave-type/generateLeaveTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLeaveTypeBy = (data) => this.authFetch({
        url: 'leave-type/getLeaveTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLeaveTypeByCode = (data) => this.authFetch({
        url: 'leave-type/getLeaveTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateLeaveTypeBy = (data) => this.authFetch({
        url: 'leave-type/updateLeaveTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertLeaveType = (data) => this.authFetch({
        url: 'leave-type/insertLeaveType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteLeaveTypeByCode = (data) => this.authFetch({
        url: 'leave-type/deleteLeaveTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
}