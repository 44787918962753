import { ServiceFetch } from '../service-fetch'

export default class CompanyBranchModel extends ServiceFetch {
    generateCompanyBranchLastCode = (data) => this.authFetch({
        url: 'company-branch/generateCompanyBranchLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCompanyBranchBy = (data) => this.authFetch({
        url: 'company-branch/getCompanyBranchBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCompanyBranchByCode = (data) => this.authFetch({
        url: 'company-branch/getCompanyBranchByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCompanyBranchByID = (data) => this.authFetch({
        url: 'company-branch/getCompanyBranchByID',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCompanyBranchBy = (data) => this.authFetch({
        url: 'company-branch/updateCompanyBranchBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCompanyBranch = (data) => this.authFetch({
        url: 'company-branch/insertCompanyBranch',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCompanyBranchByCode = (data) => this.authFetch({
        url: 'company-branch/deleteCompanyBranchByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}