import { ServiceFetch } from '../service-fetch'

export default class BankModel extends ServiceFetch {
    generateBankLastCode = (data) => this.authFetch({
        url: 'bank/generateBankLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBankBy = (data) => this.authFetch({
        url: 'bank/getBankBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getBankByCode = (data) => this.authFetch({
        url: 'bank/getBankByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateBankBy = (data) => this.authFetch({
        url: 'bank/updateBankBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertBank = (data) => this.authFetch({
        url: 'bank/insertBank',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteBankByCode = (data) => this.authFetch({
        url: 'bank/deleteBankByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}