import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierMaterialListModel extends ServiceFetch {
    getInvoiceSupplierMaterialListBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list/getInvoiceSupplierMaterialListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierMaterialListBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list/getLastInvoiceSupplierMaterialListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateInvoiceSupplierMaterialListBy = (data) => this.authFetch({
        url: 'invoice-supplier-material-list/generateInvoiceSupplierMaterialListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}