import { ServiceFetch } from '../service-fetch'

export default class UserPositionModel extends ServiceFetch {
    generateUserPositionLastCode = (data) => this.authFetch({
        url: 'user-position/generateUserPositionLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserPositionBy = (data) => this.authFetch({
        url: 'user-position/getUserPositionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserPositionByCode = (data) => this.authFetch({
        url: 'user-position/getUserPositionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateUserPositionBy = (data) => this.authFetch({
        url: 'user-position/updateUserPositionBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertUserPosition = (data) => this.authFetch({
        url: 'user-position/insertUserPosition',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteUserPositionByCode = (data) => this.authFetch({
        url: 'user-position/deleteUserPositionByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}