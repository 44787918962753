import { ServiceFetch } from '../service-fetch'

export default class PettyCashWithdrawalListModel extends ServiceFetch {
    getPettyCashWithdrawalListBy = (data) => this.authFetch({
        url: 'petty-cash-withdrawal-list/getPettyCashWithdrawalListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generatePettyCashWithdrawalListBy = (data) => this.authFetch({
        url: 'petty-cash-withdrawal-list/generatePettyCashWithdrawalListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

}