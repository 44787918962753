import { ServiceFetch } from '../service-fetch'

export default class ProductCategoryModel extends ServiceFetch {
    generateProductCategoryLastCode = (data) => this.authFetch({
        url: 'product-category/generateProductCategoryLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductCategoryBy = (data) => this.authFetch({
        url: 'product-category/getProductCategoryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductCategoryByCode = (data) => this.authFetch({
        url: 'product-category/getProductCategoryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductCategoryBy = (data) => this.authFetch({
        url: 'product-category/updateProductCategoryBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductCategory = (data) => this.authFetch({
        url: 'product-category/insertProductCategory',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductCategoryByCode = (data) => this.authFetch({
        url: 'product-category/deleteProductCategoryByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}