import { ServiceFetch } from '../service-fetch'

export default class BillingNoteSupplierListModel extends ServiceFetch {
    getBillingNoteSupplierListBy = (data) => this.authFetch({
        url: 'billing-note-supplier-list/getBillingNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateBillingNoteSupplierListBy = (data) => this.authFetch({
        url: 'billing-note-supplier-list/generateBillingNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}