import { ServiceFetch } from '../service-fetch'

export default class TimeWorkModel extends ServiceFetch {
    generateTimeWorkLastCode = (data) => this.authFetch({
        url: 'time-work/generateTimeWorkLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getTimeWorkBy = (data) => this.authFetch({
        url: 'time-work/getTimeWorkBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getTimeWorkByCode = (data) => this.authFetch({
        url: 'time-work/getTimeWorkByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateTimeWorkBy = (data) => this.authFetch({
        url: 'time-work/updateTimeWorkBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertTimeWork = (data) => this.authFetch({
        url: 'time-work/insertTimeWork',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteTimeWorkByCode = (data) => this.authFetch({
        url: 'time-work/deleteTimeWorkByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}