import { ServiceFetch } from '../service-fetch'

export default class WithdrawalListModel extends ServiceFetch {
    getWithdrawalListBy = (data) => this.authFetch({
        url: 'withdrawal-list/getWithdrawalListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSignatureByCode = (data) => this.authFetch({
        url: 'withdrawal-list/updateSignatureByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}