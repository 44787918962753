import { ServiceFetch } from '../service-fetch'

export default class ProductBrandModel extends ServiceFetch {
    generateProductBrandLastCode = (data) => this.authFetch({
        url: 'product-brand/generateProductBrandLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductBrandBy = (data) => this.authFetch({
        url: 'product-brand/getProductBrandBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductBrandByCode = (data) => this.authFetch({
        url: 'product-brand/getProductBrandByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductBrandBy = (data) => this.authFetch({
        url: 'product-brand/updateProductBrandBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductBrand = (data) => this.authFetch({
        url: 'product-brand/insertProductBrand',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductBrandByCode = (data) => this.authFetch({
        url: 'product-brand/deleteProductBrandByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}