import { ServiceFetch } from '../service-fetch'

export default class FactStockUnpackModel extends ServiceFetch {
    getFactStockUnpackBy = (data) => this.authFetch({
        url: 'fact-stock-unpack/getFactStockUnpackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    genarateFactStockUnpackBy = (data) => this.authFetch({
        url: 'fact-stock-unpack/genarateFactStockUnpackBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}