import { ServiceFetch } from '../service-fetch'

export default class UserModel extends ServiceFetch {
    checkLogin = (data) => this.authFetch({
        url: 'user/checkLogin',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateUserLastCode = (data) => this.authFetch({
        url: 'user/generateUserLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserBy = (data) => this.authFetch({
        url: 'user/getUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getUserByCode = (data) => this.authFetch({
        url: 'user/getUserByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkUsernameBy = (data) => this.authFetch({
        url: 'user/checkUsernameBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateUserBy = (data) => this.authFetch({
        url: 'user/updateUserBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertUser = (data) => this.authFetch({
        url: 'user/insertUser',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteUserByCode = (data) => this.authFetch({
        url: 'user/deleteUserByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}