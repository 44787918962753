import { ServiceFetch } from '../service-fetch'

export default class SaleChangeModel extends ServiceFetch {
    generateSaleChangeLastCode = (data) => this.authFetch({
        url: 'sale-change/generateSaleChangeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleChangeBy = (data) => this.authFetch({
        url: 'sale-change/getSaleChangeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSaleChangeByCode = (data) => this.authFetch({
        url: 'sale-change/getSaleChangeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateSaleChangeBy = (data) => this.authFetch({
        url: 'sale-change/updateSaleChangeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertSaleChange = (data) => this.authFetch({
        url: 'sale-change/insertSaleChange',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteSaleChangeByCode = (data) => this.authFetch({
        url: 'sale-change/deleteSaleChangeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}