import { ServiceFetch } from '../service-fetch'

export default class ChangeDayOffModel extends ServiceFetch {
    generateChangeDayOffLastCode = (data) => this.authFetch({
        url: 'change-day-off/generateChangeDayOffLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChangeDayOffBy = (data) => this.authFetch({
        url: 'change-day-off/getChangeDayOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getChangeDayOffByCode = (data) => this.authFetch({
        url: 'change-day-off/getChangeDayOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateChangeDayOffBy = (data) => this.authFetch({
        url: 'change-day-off/updateChangeDayOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertChangeDayOff = (data) => this.authFetch({
        url: 'change-day-off/insertChangeDayOff',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteChangeDayOffByCode = (data) => this.authFetch({
        url: 'change-day-off/deleteChangeDayOffByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approveChangeDayOffBy = (data) => this.authFetch({
        url: 'change-day-off/approveChangeDayOffBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}