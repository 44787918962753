import { ServiceFetch } from '../service-fetch'

export default class ScheduleModel extends ServiceFetch {

    generateScheduleLastCode = (data) => this.authFetch({
        url: "schedule/generateScheduleLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getScheduleBy = (data) => this.authFetch({
        url: "schedule/getScheduleBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getScheduleByCode = (data) => this.authFetch({
        url: "schedule/getScheduleByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateScheduleBy = (data) => this.authFetch({
        url: "schedule/updateScheduleBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertSchedule = (data) => this.authFetch({
        url: "schedule/insertSchedule",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteScheduleByCode = (data) => this.authFetch({
        url: "schedule/deleteScheduleByCode",
        method: "POST",
        body: JSON.stringify(data),
    })
}