import { ServiceFetch } from '../service-fetch'

export default class PostponeBreakModel extends ServiceFetch {
    generatePostponeBreakLastCode = (data) => this.authFetch({
        url: 'postpone-break/generatePostponeBreakLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPostponeBreakBy = (data) => this.authFetch({
        url: 'postpone-break/getPostponeBreakBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPostponeBreakByCode = (data) => this.authFetch({
        url: 'postpone-break/getPostponeBreakByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updatePostponeBreakBy = (data) => this.authFetch({
        url: 'postpone-break/updatePostponeBreakBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertPostponeBreak = (data) => this.authFetch({
        url: 'postpone-break/insertPostponeBreak',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deletePostponeBreakByCode = (data) => this.authFetch({
        url: 'postpone-break/deletePostponeBreakByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    approvePostponeBreakBy = (data) => this.authFetch({
        url: 'postpone-break/approvePostponeBreakBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}