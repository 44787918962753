import { env } from '../config/global'
import { token } from '../config/access-token'

export class ServiceFileManager {
  uploadFile = async (data) => {
    const { upload_path, src } = data

    if (src && src.file) {
      const form_data = new FormData()

      form_data.append('upload_path', upload_path)
      form_data.append('files', src.file)

      const res_upload = await fetch(`${env.service_url}file-upload/uploadFile`, {
        method: 'post',
        headers: {
          'x-access-token': token['x-access-token'],
        },
        body: form_data,
      }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], err: error }))

      if (res_upload.require) {
        src.old && await this.deleteFile({ file_path: src.old })

        return res_upload.data.file_name
      } else {
        return src.old
      }
    } else {
      return src.old
    }
  }

  uploadBase64Image = async (data) => {
    const res_upload = await fetch(`${env.service_url}file-upload/uploadBase64Image`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'x-access-token': token['x-access-token'],
      },
      body: JSON.stringify(data),
    }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], err: error }))

    return res_upload.require ? res_upload.data.file_name : ''
  }

  deleteFile = (data) => fetch(`${env.service_url}file-upload/deleteFlie`, {
    method: 'post',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'x-access-token': token['x-access-token'],
    },
    body: JSON.stringify(data),
  }).then((response) => response.json().then((e) => e)).catch((error) => ({ require: false, data: [], err: error }))
}