import { ServiceFetch } from '../service-fetch'

export default class DebitReceiptTypeModel extends ServiceFetch {
    generateDebitReceiptTypeLastCode = (data) => this.authFetch({
        url: 'debit-receipt-type/generateDebitReceiptTypeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitReceiptTypeBy = (data) => this.authFetch({
        url: 'debit-receipt-type/getDebitReceiptTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitReceiptTypeByCode = (data) => this.authFetch({
        url: 'debit-receipt-type/getDebitReceiptTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitReceiptTypeBy = (data) => this.authFetch({
        url: 'debit-receipt-type/updateDebitReceiptTypeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitReceiptType = (data) => this.authFetch({
        url: 'debit-receipt-type/insertDebitReceiptType',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitReceiptTypeByCode = (data) => this.authFetch({
        url: 'debit-receipt-type/deleteDebitReceiptTypeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}