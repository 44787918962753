import { ServiceFetch } from '../service-fetch'

export default class PaymentPayModel extends ServiceFetch {
    getPaymentPayBy = (data) => this.authFetch({
        url: 'payment-pay/getPaymentPayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getManyPaymentPay = (data) => this.authFetch({
        url: 'payment-pay/getManyPaymentPay',
        method: 'POST',
        body: JSON.stringify(data),
    })
}