import { ServiceFetch } from '../service-fetch'

export default class ProductBarcodeModel extends ServiceFetch {
    getProductBarcodeBy = (data) => this.authFetch({
        url: 'product-barcode/getProductBarcodeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkProductBarcodeBy = (data) => this.authFetch({
        url: 'product-barcode/checkProductBarcodeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductBarcodeByCode = (data) => this.authFetch({
        url: 'product-barcode/updateProductBarcodeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}