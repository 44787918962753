import { ServiceFetch } from '../service-fetch'

export default class ProductUnitModel extends ServiceFetch {

  getProductUnitBy = (data) => this.authFetch({
    url: 'product-unit/get-product-unit-by',
    method: 'POST',
    body: JSON.stringify(data),
  }) 

  getProductUnitByCode = (data) => this.authFetch({
    url: 'product-unit/get-product-unit-by-code', 
    method: 'POST',
    body: JSON.stringify(data)
  })

  insertProductUnit = (data) => this.authFetch({
    url: 'product-unit/insert-product-unit', 
    method: 'POST',
    body: JSON.stringify(data)
  })

  updateProductUnit = (data) => this.authFetch({
    url: 'product-unit/update-product-unit',
    method: 'POST',
    body: JSON.stringify(data)
  })

  deleteProductUnit = (data) => this.authFetch({
    url: 'product-unit/delete-product-unit',
    method: 'POST',
    body: JSON.stringify(data)
  })
}