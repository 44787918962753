import { ServiceFetch } from '../service-fetch'

export default class DebitNoteSupplierOtherModel extends ServiceFetch {
    generateDebitNoteSupplierOtherLastCode = (data) => this.authFetch({
        url: 'debit-note-supplier-other/generateDebitNoteSupplierOtherLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteSupplierOtherBy = (data) => this.authFetch({
        url: 'debit-note-supplier-other/getDebitNoteSupplierOtherBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitNoteSupplierOtherByCode = (data) => this.authFetch({
        url: 'debit-note-supplier-other/getDebitNoteSupplierOtherByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitNoteSupplierOtherBy = (data) => this.authFetch({
        url: 'debit-note-supplier-other/updateDebitNoteSupplierOtherBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitNoteSupplierOther = (data) => this.authFetch({
        url: 'debit-note-supplier-other/insertDebitNoteSupplierOther',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitNoteSupplierOtherByCode = (data) => this.authFetch({
        url: 'debit-note-supplier-other/deleteDebitNoteSupplierOtherByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}