import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierModel extends ServiceFetch {
    generateInvoiceSupplierLastCode = (data) => this.authFetch({
        url: 'invoice-supplier/generateInvoiceSupplierLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierBy = (data) => this.authFetch({
        url: 'invoice-supplier/getInvoiceSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierByCode = (data) => this.authFetch({
        url: 'invoice-supplier/getInvoiceSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastInvoiceSupplierBy = (data) => this.authFetch({
        url: 'invoice-supplier/getLastInvoiceSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseSupplierInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/getAdviseSupplierInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdvisePurchaseOrderInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/getAdvisePurchaseOrderInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierBy = (data) => this.authFetch({
        url: 'invoice-supplier/updateInvoiceSupplierBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getLastSupplierInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/getLastSupplierInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertInvoiceSupplier = (data) => this.authFetch({
        url: 'invoice-supplier/insertInvoiceSupplier',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceSupplierByCode = (data) => this.authFetch({
        url: 'invoice-supplier/deleteInvoiceSupplierByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierReplyClaimRemark = (data) => this.authFetch({
        url: 'invoice-supplier/updateInvoiceSupplierReplyClaimRemark',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalesDataBy = (data) => this.authFetch({
        url: 'invoice-supplier/getSalesDataBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPaymentDataBy = (data) => this.authFetch({
        url: 'invoice-supplier/getPaymentDataBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getPaymentListBy = (data) => this.authFetch({
        url: 'invoice-supplier/getPaymentListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitDataPerYearBy = (data) => this.authFetch({
        url: 'invoice-supplier/getDebitDataPerYearBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getSalesAllCompanyBranchBy = (data) => this.authFetch({
        url: 'invoice-supplier/getSalesAllCompanyBranchBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    checkInvoiceSupplierPaperCodeBy = (data) => this.authFetch({
        url: 'invoice-supplier/checkInvoiceSupplierPaperCodeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}