import { ServiceFetch } from '../service-fetch'

export default class DebitAccountModel extends ServiceFetch {
    generateDebitAccountLastCode = (data) => this.authFetch({
        url: 'debit-account/generateDebitAccountLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitAccountBy = (data) => this.authFetch({
        url: 'debit-account/getDebitAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getDebitAccountByCode = (data) => this.authFetch({
        url: 'debit-account/getDebitAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateDebitAccountBy = (data) => this.authFetch({
        url: 'debit-account/updateDebitAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertDebitAccount = (data) => this.authFetch({
        url: 'debit-account/insertDebitAccount',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteDebitAccountByCode = (data) => this.authFetch({
        url: 'debit-account/deleteDebitAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}