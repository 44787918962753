import { ServiceFetch } from '../service-fetch'

export default class ProductGroupModel extends ServiceFetch {
    generateProductGroupLastCode = (data) => this.authFetch({
        url: 'product-group/generateProductGroupLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductGroupBy = (data) => this.authFetch({
        url: 'product-group/getProductGroupBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getProductGroupByCode = (data) => this.authFetch({
        url: 'product-group/getProductGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateProductGroupBy = (data) => this.authFetch({
        url: 'product-group/updateProductGroupBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertProductGroup = (data) => this.authFetch({
        url: 'product-group/insertProductGroup',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteProductGroupByCode = (data) => this.authFetch({
        url: 'product-group/deleteProductGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}