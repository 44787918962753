import { ServiceFetch } from '../service-fetch'

export default class CreditNoteSupplierListModel extends ServiceFetch {
    getCreditNoteSupplierListBy = (data) => this.authFetch({
        url: 'credit-note-supplier-list/getCreditNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateCreditNoteSupplierListBy = (data) => this.authFetch({
        url: 'credit-note-supplier-list/generateCreditNoteSupplierListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}