import { ServiceFetch } from '../service-fetch'

export default class JournalRevenueModel extends ServiceFetch {
  generateJournalRevenueLastCode = (data) => this.authFetch({
    url: 'journal-revenue/generateJournalRevenueLastCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getJournalRevenueBy = (data) => this.authFetch({
    url: 'journal-revenue/getJournalRevenueBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getJournalRevenueByCode = (data) => this.authFetch({
    url: 'journal-revenue/getJournalRevenueByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  updateJournalRevenueBy = (data) => this.authFetch({
    url: 'journal-revenue/updateJournalRevenueBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  insertJournalRevenue = (data) => this.authFetch({
    url: 'journal-revenue/insertJournalRevenue',
    method: 'POST',
    body: JSON.stringify(data),
  })

  deleteJournalRevenueByCode = (data) => this.authFetch({
    url: 'journal-revenue/deleteJournalRevenueByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  recordJournalRevenueBy = (data) => this.authFetch({
    url: 'journal-revenue/recordJournalRevenueBy',
    method: 'POST',
    body: JSON.stringify(data),
  })
}