import { ServiceFetch } from '../service-fetch'

export default class CreditAccountModel extends ServiceFetch {
    generateCreditAccountLastCode = (data) => this.authFetch({
        url: 'credit-account/generateCreditAccountLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditAccountBy = (data) => this.authFetch({
        url: 'credit-account/getCreditAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getCreditAccountByCode = (data) => this.authFetch({
        url: 'credit-account/getCreditAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateCreditAccountBy = (data) => this.authFetch({
        url: 'credit-account/updateCreditAccountBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertCreditAccount = (data) => this.authFetch({
        url: 'credit-account/insertCreditAccount',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteCreditAccountByCode = (data) => this.authFetch({
        url: 'credit-account/deleteCreditAccountByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}