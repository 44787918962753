import { ServiceFetch } from '../service-fetch'

export default class PurchaseDepositListModel extends ServiceFetch {
    getPurchaseDepositListBy = (data) => this.authFetch({
        url: 'purchase-deposit-list/getPurchaseDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generatePurchaseDepositListBy = (data) => this.authFetch({
        url: 'purchase-deposit-list/generatePurchaseDepositListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}