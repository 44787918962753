import { ServiceFetch } from '../service-fetch'

export default class AssetModel extends ServiceFetch {

    generateAssetLastCode = (data) => this.authFetch({
        url: 'asset/generateAssetLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAssetBy = (data) => this.authFetch({
        url: 'asset/getAssetBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAssetByCode = (data) => this.authFetch({
        url: 'asset/getAssetByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateAssetBy = (data) => this.authFetch({
        url: 'asset/updateAssetBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertAsset = (data) => this.authFetch({
        url: 'asset/insertAsset',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteAssetByCode = (data) => this.authFetch({
        url: 'asset/deleteAssetByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

}