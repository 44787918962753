import { ServiceFetch } from '../service-fetch'

export default class AssetGroupModel extends ServiceFetch {
    
    getAssetGroupBy = (data) => this.authFetch({
        url: 'asset-group/getAssetGroupBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAssetGroupByCode = (data) => this.authFetch({
        url: 'asset-group/getAssetGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateAssetGroupLastCode = (data) => this.authFetch({
        url: 'asset-group/generateAssetGroupLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    insertAssetGroup = (data) => this.authFetch({
        url: 'asset-group/insertAssetGroup',
        method: 'POST',
        body: JSON.stringify(data),
    })
    
    updateAssetGroupByCode = (data) => this.authFetch({
        url: 'asset-group/updateAssetGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteAssetGroupByCode = (data) => this.authFetch({
        url: 'asset-group/deleteAssetGroupByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}