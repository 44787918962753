import { ServiceFetch } from '../service-fetch'

export default class WithholdingModel extends ServiceFetch {

    generateWithholdingLastCode = (data) => this.authFetch({
        url: 'withholding/generateWithholdingLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getWithholdingBy = (data) => this.authFetch({
        url: 'withholding/getWithholdingBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getWithholdingByCode = (data) => this.authFetch({
        url: 'withholding/getWithholdingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateWithholdingByCode = (data) => this.authFetch({
        url: 'withholding/updateWithholdingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertWithholding = (data) => this.authFetch({
        url: 'withholding/insertWithholding',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteWithholdingByCode = (data) => this.authFetch({
        url: 'withholding/deleteWithholdingByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

}