import { ServiceFetch } from '../service-fetch'

export default class InvoiceSupplierDraftModel extends ServiceFetch {
    generateInvoiceSupplierDraftLastCode = (data) => this.authFetch({
        url: 'invoice-supplier-draft/generateInvoiceSupplierDraftLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-draft/getInvoiceSupplierDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getInvoiceSupplierDraftByCode = (data) => this.authFetch({
        url: 'invoice-supplier-draft/getInvoiceSupplierDraftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateInvoiceSupplierDraftBy = (data) => this.authFetch({
        url: 'invoice-supplier-draft/updateInvoiceSupplierDraftBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 

    insertInvoiceSupplierDraft = (data) => this.authFetch({
        url: 'invoice-supplier-draft/insertInvoiceSupplierDraft',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteInvoiceSupplierDraftByCode = (data) => this.authFetch({
        url: 'invoice-supplier-draft/deleteInvoiceSupplierDraftByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })
}