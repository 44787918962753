import { ServiceFetch } from '../service-fetch'

export default class SchedulePlanSalesmanModel extends ServiceFetch {
    generateSchedulePlanSalesmanLastCode = (data) => this.authFetch({
        url: "schedule-plan-salesman/generateSchedulePlanSalesmanLastCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSchedulePlanSalesmanBy = (data) => this.authFetch({
        url: "schedule-plan-salesman/getSchedulePlanSalesmanBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    getSchedulePlanSalesmanByCode = (data) => this.authFetch({
        url: "schedule-plan-salesman/getSchedulePlanSalesmanByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    approveSchedulePlanSalemansBy = (data) => this.authFetch({
        url: "schedule-plan-salesman/approveSchedulePlanSalemansBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    updateSchedulePlanSalesmanBy = (data) => this.authFetch({
        url: "schedule-plan-salesman/updateSchedulePlanSalesmanBy",
        method: "POST",
        body: JSON.stringify(data),
    })

    insertSchedulePlanSalesman = (data) => this.authFetch({
        url: "schedule-plan-salesman/insertSchedulePlanSalesman",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSchedulePlanSalesmanByCode = (data) => this.authFetch({
        url: "schedule-plan-salesman/deleteSchedulePlanSalesmanByCode",
        method: "POST",
        body: JSON.stringify(data),
    })

    deleteSchedulePlanSalesmanByDate = (data) => this.authFetch({
        url: "schedule-plan-salesman/deleteSchedulePlanSalesmanByDate",
        method: "POST",
        body: JSON.stringify(data),
    })

}