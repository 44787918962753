import { ServiceFetch } from '../service-fetch'

export default class FactStockReturnModel extends ServiceFetch {
    getAdviseStockIssueReturnBy = (data) => this.authFetch({
        url: 'fact-stock-return/getAdviseStockIssueReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getAdviseStockIssueListReturnBy = (data) => this.authFetch({
        url: 'fact-stock-return/getAdviseStockIssueListReturnBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}