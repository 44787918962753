import { ServiceFetch } from '../service-fetch'

export default class StockRequestMoveMainListModel extends ServiceFetch {
    getStockRequestMoveMainListBy = (data) => this.authFetch({
        url: 'stock-request-move-main-list/getStockRequestMoveMainListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockRequestMoveMainListBy = (data) => this.authFetch({
        url: 'stock-request-move-main-list/generateStockRequestMoveMainListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}