import { ServiceFetch } from '../service-fetch'

export default class EmployeeModel extends ServiceFetch {
    generateEmployeeLastCode = (data) => this.authFetch({
        url: 'employee/generateEmployeeLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeBy = (data) => this.authFetch({
        url: 'employee/getEmployeeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeByCode = (data) => this.authFetch({
        url: 'employee/getEmployeeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateEmployeeBy = (data) => this.authFetch({
        url: 'employee/updateEmployeeBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertEmployee = (data) => this.authFetch({
        url: 'employee/insertEmployee',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteEmployeeByCode = (data) => this.authFetch({
        url: 'employee/deleteEmployeeByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeePayrollDataBy = (data) => this.authFetch({
        url: 'employee/getEmployeePayrollDataBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}