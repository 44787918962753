import { ServiceFetch } from '../service-fetch'

export default class BillingNotePaymentModel extends ServiceFetch {
    getPaymentPayBy = (data) => this.authFetch({
        url: 'billing-note-payment/getPaymentPayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getManyPaymentPayBy = (data) => this.authFetch({
        url: 'billing-note-payment/getManyPaymentPayBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}