import { ServiceFetch } from '../service-fetch'

export default class PromotionListModel extends ServiceFetch {
    getPromotionListBy = (data) => this.authFetch({
        url: 'promotion-list/getPromotionListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
    getPromotionDetailListBy = (data) => this.authFetch({
        url: 'promotion-list/getPromotionDetailListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}