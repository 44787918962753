import { ServiceFetch } from '../service-fetch'

export default class EmployeeWarningModel extends ServiceFetch {
    generateEmployeeWarningLastCode = (data) => this.authFetch({
        url: 'employee-warning/generateEmployeeWarningLastCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeWarningBy = (data) => this.authFetch({
        url: 'employee-warning/getEmployeeWarningBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    getEmployeeWarningByCode = (data) => this.authFetch({
        url: 'employee-warning/getEmployeeWarningByCode',
        method: 'POST',
        body: JSON.stringify(data),
    })

    updateEmployeeWarningBy = (data) => this.authFetch({
        url: 'employee-warning/updateEmployeeWarningBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    insertEmployeeWarning = (data) => this.authFetch({
        url: 'employee-warning/insertEmployeeWarning',
        method: 'POST',
        body: JSON.stringify(data),
    })

    deleteEmployeeWarningByCode = (data) => this.authFetch({
        url: 'employee-warning/deleteEmployeeWarningByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    
    approveEmployeeWarningBy = (data) => this.authFetch({
        url: 'employee-warning/approveEmployeeWarningBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    
    updateSignatureByCode = (data) => this.authFetch({
        url: 'employee-warning/updateSignatureByCode',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
}