import { ServiceFetch } from '../service-fetch'

export default class ProductPriceHistoryModel extends ServiceFetch {
    getProductPriceHistoryBy = (data) => this.authFetch({
        url: 'product-price-history/getProductPriceHistoryBy',
        method: 'POST',
        body: JSON.stringify(data),
    }) 
    insertProductPriceHistory = (data) => this.authFetch({
        url: 'product-price-history/insertProductPriceHistory',
        method: 'POST',
        body: JSON.stringify(data),
    })
}