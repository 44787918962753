import { ServiceFetch } from '../service-fetch'

export default class StockMoveMainListModel extends ServiceFetch {
    getStockMoveMainListBy = (data) => this.authFetch({
        url: 'stock-move-main-list/getStockMoveMainListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })

    generateStockMoveMainListBy = (data) => this.authFetch({
        url: 'stock-move-main-list/generateStockMoveMainListBy',
        method: 'POST',
        body: JSON.stringify(data),
    })
}