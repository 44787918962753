import { ServiceFetch } from '../service-fetch'

export default class AccountRevenueModel extends ServiceFetch {
  generateAccountRevenueLastCode = (data) => this.authFetch({
    url: 'account-revenue/generateAccountRevenueLastCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getAccountRevenueBy = (data) => this.authFetch({
    url: 'account-revenue/getAccountRevenueBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getAccountRevenueByCode = (data) => this.authFetch({
    url: 'account-revenue/getAccountRevenueByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getAccountRevenueJournalTypeBy = (data) => this.authFetch({
    url: 'account-revenue/getAccountRevenueJournalTypeBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  getAccountRevenueIncomeStatementBy = (data) => this.authFetch({
    url: 'account-revenue/getAccountRevenueIncomeStatementBys',
    method: 'POST',
    body: JSON.stringify(data),
  })

  updateAccountRevenueBy = (data) => this.authFetch({
    url: 'account-revenue/updateAccountRevenueBy',
    method: 'POST',
    body: JSON.stringify(data),
  })

  createAccountRevenue = (data) => this.authFetch({
    url: 'account-revenue/createAccountRevenue',
    method: 'POST',
    body: JSON.stringify(data),
  })

  insertAccountRevenue = (data) => this.authFetch({
    url: 'account-revenue/insertAccountRevenue',
    method: 'POST',
    body: JSON.stringify(data),
  })

  deleteAccountRevenueByCode = (data) => this.authFetch({
    url: 'account-revenue/deleteAccountRevenueByCode',
    method: 'POST',
    body: JSON.stringify(data),
  })
}